<template>
  <!--知识库管理-->
  <div style="padding: 20px">
    <div v-if="showEdit === false">
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist-botton" style="margin-left: 20px">
                <el-button
                  class="custom-button"
                  style="
                    background: #fea837 !important;
                    border-color: #fea837 !important;
                    color: #ffffff !important;
                  "
                  @click="openadd(null)"
                  v-auths="[`${$config.uniquePrefix}operate:Versionupdate:save`]"
                 
                >
                  <i class="el-icon-tianjia" />
                  <span style="vertical-align: middle">发布版本</span>
                </el-button>
              </div>
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :headerCellStyle="headerCellStyle"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <!--自定义列显示 示例-->
              <template v-slot:is_force="{ scope }">
                <div v-if="scope.row.is_force === 0">
                  <span
                    style="
                      width: 8px;
                      height: 8px;
                      background: #25eb61;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span>是</span>
                </div>
                <div v-else>
                  <span
                    style="
                      width: 8px;
                      height: 8px;
                      background: #ff2525;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span>否</span>
                </div>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>
    </div>
    <!--列表设置弹窗-->
    <el-dialog
      title="添加版本信息"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <!-- <span>这是一段信息</span> -->
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="包名">
          <el-input v-model="form.package"></el-input>
        </el-form-item>
        <el-form-item label="平台类型">
          <el-radio-group v-model="form.device_type">
            <el-radio label="android"></el-radio>
            <el-radio label="ios"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="更新说明">
          <el-input type="textarea" v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item label="下载地址">
          <el-upload
            action="none"
            :limit="1"
            :http-request="readExcel"
            :file-list="fileList"
            :before-upload="adds"
            :on-exceed="duoyu"
            :on-remove="remove"
          >
          
            <i class="el-icon-upload"></i>
            <div v-if="progressPercent">
               <el-progress style="width: 200px;margin-top: 8px" :text-inside="true" :stroke-width="20" :percentage="progressPercent" />
            </div>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="下载链接">
          <el-input v-model="form.name"></el-input>
        </el-form-item> -->
        <el-form-item label="强制">
          <el-radio-group v-model="form.is_force">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="版本号">
          <el-input v-model="form.version"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quxiao">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//引入文章详情组件
// import Details from './details.vue'
// 获取配置
import setting from "@/config/setting";
import { getCookies } from "@/utils/cookies";
import Config from "@/config";

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import { ShowTable } from "@/utils";

// 引入的接口
import {
  app_version,
  add_version,
  upload_app,
  upload_section,
  upload_merge_section,
} from "@/api/operate";

// 用来请求公共
import axios from "axios";

export default {
  // 组件生效
  components: {
    PackageSortElement,
  },
  data() {
    return {
      action: setting.baseUrl + "common/upload/capacity",
      form: {},
      dialogVisible: false,
      progressPercent: 0,
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label:"包名",
          prop: "package",
          isShow: true,
        },
        {
          label: "版本号",
          // type: "index",
          // width: "70"
          prop: "version",
          isShow: true,
        },
        // {
        //   label: "二级栏目",
        //   prop: "facilitator_code",
        //   isShow: true
        // },
        // {
        //   label: "三级栏目",
        //   prop: "facilitator_name",
        //   isShow: true
        // },
        {
          label: "平台类型",
          prop: "device_type",
          isShow: true,
        },
        {
          label: "升级信息",
          prop: "description",
          isShow: true,
        },
        {
          label: "是否强制",
          prop: "is_force",
          slot: "is_force",
          isShow: true,
        },
        {
          label: "发布日期",
          prop: "create_time",
          isShow: true,
        },
        {
          label: "下载地址",
          width: "200",
          prop: "url",
          //   slot: "operationSlots",
          isShow: true,
        },
      ],

      // 列表设置弹窗
      ListSetupShow: false,

      current: null,
      showEdit: false,

      suipian: [],
      //上传文件列表
       fileList: [],
       file_flag:false,
    };
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getList();
  },

  methods: {
    // 获取列表
    getList() {
      app_version(this.where)
        .then(async (res) => {
          this.total = res.data.count;
          this.List = res.data.list;
          this.loading = false;
        })
        .catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        });
    },
    
    // 查询条件
    QueryList() {
      this.getList();
    },
    // 清空
    empty() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val);
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection);
      console.log("选择的所有数据行", selection);
      console.log("当前选择的数据行", row);
    },
    ChangeSize(val) {
      console.log("当前页面显示的条数", val);
      this.where.limit = val;
      this.getList();
    },

    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },

    openadd() {
      this.dialogVisible = true;

    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
          this.quxiao();
        })
        .catch((_) => {});
    },
    async readExcel(param) {
      const file = param.file;
      const chunkSize = 10 * 1024 * 1024;
      //1MB
      const totalChunks = Math.ceil(file.size / chunkSize);
      const chunks = [];
      this.suipian = []
      let offset = 0;
      this.file_flag = true
      for (let index = 0; index < totalChunks; index++) {
        const chunk = file.slice(offset, offset + chunkSize);
        chunks.push(chunk);
        offset += chunkSize;
      }
      for (let index = 0; index < totalChunks; index++) {
        const formData = new FormData();
        formData.append("file", chunks[index]);
        // 发送每个切片到服务器
        await axios
          .post(
            setting.apiBaseURL + "system/app_version/upload_section",
            formData,
            {
              headers: {
                "X-token": "Bearer" + " " + getCookies(Config.tokenStoreName),
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            if(res.data.code==200){
              console.log(this.progressPercent,(100/totalChunks))
              this.progressPercent = parseFloat((this.progressPercent + (100/totalChunks)).toFixed(2))
              this.suipian.push(res.data.data.source_name);
            }else{
            this.$message({message:'安装包上传失败，请重新上传',type:'error',customClass:'zZindex'});
            this.form.url = ""
            this.suipian = []
            this.fileList = []
            this.progressPercent=0
            this.file_flag = false
           }
           
            
          }).catch((err) => {
             this.$message({message:'安装包上传失败，请重新上传',type:'error',customClass:'zZindex'});
            this.form.url = ""
            this.suipian = []
            this.fileList = []
            this.progressPercent=0
            this.file_flag = false
          })
      }

      // 文件碎片合并
      await axios
        .post(
          setting.apiBaseURL + "system/app_version/upload_merge_section",
          {
            device_type: this.form.device_type,
            list: this.suipian,
          },
          {
            headers: {
              "X-token": "Bearer" + " " + getCookies(Config.tokenStoreName),
            },
          }
        )
        .then((res) => {
          // console.log(res)
          if(res.data.code == 200){
            console.log(res.data.data)
             this.progressPercent=0
            this.form.url = res.data.data.url
          }else{
            this.$message({message:'安装包上传失败，请重新上传',type:'error',customClass:'zZindex'});
            this.form.url = ""
            this.suipian = []
            this.fileList = []
            this.progressPercent=0
            this.file_flag = false
          }
        }).catch((err) => {
            this.$message({message:'安装包上传失败，请重新上传',type:'error',customClass:'zZindex'});
            this.form.url = ""
            this.suipian = []
            this.fileList = []
            this.progressPercent=0
            this.file_flag = false
        })
    },
    remove(){
      
      this.form.url = ""
      console.log(this.form.url)
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      const chunkSize = 1 * 1024 * 1024;
      //1MB
      const totalChunks = Math.ceil(file.size / chunkSize);
      const chunks = [];
      let offset = 0;
      for (let index = 0; index < totalChunks; index++) {
        const chunk = file.slice(offset, offset + chunkSize);
        chunks.push(chunk);
        offset += chunkSize;
      }
      for (let index = 0; index < totalChunks; index++) {
        const formData = new FormData();
        formData.append("file", chunks[index]);
        // 发送每个切片到服务器
        const response = await axios.post("/upload", formData); // 处理服务器的响应
      }
    },
    add() {
      if(this.form.package==""||!this.form.package){
        this.$message({message:'请填写包名',type:'error',customClass:'zZindex'});
         return false;
      }
      if(this.form.url==""||!this.form.url||this.file_flag==false){
         this.$message({message:'安装包上传失败，请重新上传',type:'error',customClass:'zZindex'});
          return false;
      }
       if(this.form.version==""||!this.form.version){
        this.$message({message:'请填写版本号',type:'error',customClass:'zZindex'});
         return false;
      }
      if(this.form.is_force==""||!this.form.is_force){
        this.$message({message:'请选择是否强制升级',type:'error',customClass:'zZindex'});
         return false;
      }
      
      let data = this.form;
      add_version(data).then((res) => {
        if (res.code == 200) {
          this.dialogVisible = false;
          this.form = {};
           this.fileList = []
          this.$message.success(res.msg);
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // successUpload(file, fileList) {
    //     console.log(66)
    //     console.log(file, fileList);
    // }
    duoyu(){
      this.$Message.error("请先清除已上传的文件");
    },
    adds() {
      // console.log(this.form.resource);
      console.log(this.fileList);
    
      if (this.form.device_type && this.form.device_type != "") {
        // this.fileList = [];
        return true;
      } else {
        this.$Message.error("请选择平台类型");
        return false;
      }
    },
    quxiao() {
      this.dialogVisible = false;
      this.form = {};
      this.fileList = []
    },
  },
};
</script>

<style lang="scss" scoped>
   /deep/ .zZindex {
    z-index:3000 !important;
  }
</style>

